import MicroModal from 'micromodal';

// var menuOpen = false;

MicroModal.init();

// Closing menu modal when links are clicked (built-in data-micromodal-close doesn't do the trick)

const menuLinks = document.querySelectorAll("#modal-menu a");

Array.from(menuLinks).forEach(link => {
    link.addEventListener('click', function (event) {
        MicroModal.close('modal-menu');
    });
});



// const fireLinkedInPixel = () => {
//     const pixel = document.createElement("IMG");
//     pixel.setAttribute("src", "https://px.ads.linkedin.com/collect/?pid=1737194&conversionId=14413010&fmt=gif");
//     pixel.setAttribute("height", "1");
//     pixel.setAttribute("width", "1");
//     pixel.setAttribute("style", "display:none;");
//     document.body.appendChild(pixel);
// }


export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const validatePhone = (phone) => {
    return String(phone)
        .toLowerCase()
        .match(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        );
};

export const validateRequired = (value) => {
    return value.trim() !== '';
}