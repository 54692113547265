import { validateRequired, validateEmail, validatePhone } from './common.js';

const fields = {
    name: {
        container: document.getElementById("container-name"),
        input: document.getElementById("field-name"),
        changed: false,
    },
    email: {
        container: document.getElementById("container-email"),
        input: document.getElementById("field-email"),
        changed: false,
    },
    phone: {
        container: document.getElementById("container-phone"),
        input: document.getElementById("field-phone"),
        changed: false,
    },
    message: {
        container: document.getElementById("container-message"),
        input: document.getElementById("field-message"),
        changed: false,
    },
    gclid: {
        container: document.getElementById("gclid"),
        input: document.getElementById("gclid"),
        changed: false,
    }
}

for (const field in fields) {
    fields[field].input.addEventListener('change', function (event) {
        const name = event.target.getAttribute("id").substring(6);
        fields[name].changed = true;
        validate(false);
    });
}


const validate = (submit) => {
    // Validation
    // If validating on submit (submit = true), validate every field
    // If validating on change (submit = false), skip unchanged fields

    // Reset notifications
    document.querySelector("#contact").classList.remove('error');
    document.querySelector("#contact").classList.remove('success');

    let formValid = true;

    if (submit || fields.name.changed) {
        if (!validateRequired(fields.name.input.value)) {
            fields.name.container.classList.add("error");
            formValid = false;
        } else {
            fields.name.container.classList.remove("error");
        }
    }
    if (submit || fields.email.changed || fields.phone.changed) {
        if (!validateEmail(fields.email.input.value) && !validatePhone(fields.phone.input.value)) {
            fields.email.container.classList.add("error");
            fields.phone.container.classList.add("error");
            formValid = false;
        } else {
            fields.email.container.classList.remove("error");
            fields.phone.container.classList.remove("error");
        }
    }

    if (submit || fields.message.changed) {
        if (!validateRequired(fields.message.input.value)) {
            fields.message.container.classList.add("error");
            formValid = false;
        } else {
            fields.message.container.classList.remove("error");
        }
    }

    return formValid;
}

// Contact form submit
document.querySelector("#contact form").addEventListener('submit', async function (event) {
    event.preventDefault();
    gtag('event', 'submit_attempt', { 'form': 'contact' });
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'submit_lead_form', {});
    // fireLinkedInPixel();

    if (validate(true)) {
        gtag('event', 'submit_success', { 'form': 'contact' });
        document.querySelector('#contact .btn-icon').classList.add('loading');
        try {
            const form = document.querySelector("#contact form")
            const formData = new FormData(form);
            const response = await fetch(form.getAttribute('action'), {
                method: "POST",
                body: formData,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
            });
            if (!response.ok) {
                throw new Error("Network response was not OK");
            }
            gtag('event', 'ajax_success', { 'form': 'contact' });
            document.querySelector('#contact .btn-icon').classList.remove('loading');
            const responseJson = await response.json();
            if (responseJson.success === true) {
                gtag('event', 'response_success', { 'form': 'contact' });
                document.querySelector("#contact").classList.add('success');
                for (const field in fields) {
                    fields[field].input.value = null;
                }
            } else {
                gtag('event', 'response_error', { 'form': 'contact' });
                document.querySelector("#contact").classList.add('error');
            }
        } catch (error) {
            gtag('event', 'ajax_error', { 'form': 'contact' });
            document.querySelector('#contact .btn-icon').classList.remove('loading');
            document.querySelector("#contact").classList.add('error');
        }
    }
});